<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div v-for="(item, index) in filteredItems" :key="index" class="item">
      <Icon
        v-if="item.icon"
        :name="getIconName(item.icon)"
        class="itemIcon"
        :inline="false"
        :lazy="true"
        width="35"
        height="35"
      />
      <MaskedLink
        class="item__link"
        :redirect-to="item.urlKey"
        :text="item.title"
      />
      <ChevronRightSvg v-if="item.icon" class="itemArrow" />
    </div>
  </div>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'CmsNavigationTree',
  components: {
    Icon,
    ChevronRightSvg,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.title);
    },
  },
  methods: {
    getIconName(iconName) {
      if (iconName) {
        return iconName.includes('/') ? iconName.split('/')[1] : iconName;
      }
      return 'undefined';
    },
  },
  serverCacheKey: ({ id, updatedAt }) => {
    /* istanbul ignore next */
    return `${id}::${updatedAt}`;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.cmsNavigationTree {
  display: block;
  width: 100%;
  background: var(--color-white);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--color-alto);
  padding: 0 var(--space-2) 0 24px;
  margin: 0 #{-var(--space-2)};
  font-weight: bold;

  &:hover,
  &:focus {
    background-color: var(--color-wild-sand);
  }
}

.itemIcon {
  fill: var(--color-rolling-stone);
  padding-right: var(--space-1);
  height: 35px;
  width: 35px;
}

.item__link {
  flex-grow: 1;
  overflow: hidden;

  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-mine-shaft);
}

.itemArrow {
  height: 30px;
  width: 30px;

  fill: var(--color-primary);
}

@media #{$_mediaLUp} {
  .cmsNavigationTree.cmsNavigationTree {
    display: none !important;
  }
}
</style>
